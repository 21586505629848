import {FLAT_VIEW_TYPES, INSTALLMENT_PLAN_TYPES, MANUAL_AREA, SHORT_FLAT_TYPES} from "../constants.ts";
import {FULL_PRICE} from "../constants";

export const getFloorRanges = (prices) => {
  let floorRanges = {};
  prices?.floorRanges?.map((item) => {
    floorRanges[item.floorRange] = {
      name: item.floorRange,
      value: item,
    };
  });
  return floorRanges;
};

export const getFlatViewTypes = (floorRange) => {
  let flatViewTypes = {};
  floorRange?.value?.prices.map((priceItem) => {
    const hasCommon = flatViewTypes.hasOwnProperty(
      FLAT_VIEW_TYPES.commonFlat.id,
    );
    const hasFancy = flatViewTypes.hasOwnProperty(
      FLAT_VIEW_TYPES.fancyViewFlat.id,
    );
    if (
      priceItem.hasOwnProperty(FLAT_VIEW_TYPES.commonFlat.id) &&
      priceItem.commonFlat > 0 &&
      !hasCommon
    ) {
      flatViewTypes[FLAT_VIEW_TYPES.commonFlat.id] = {
        name: FLAT_VIEW_TYPES.commonFlat.name,
        value: FLAT_VIEW_TYPES.commonFlat,
      };
    }
    if (
      priceItem.hasOwnProperty(FLAT_VIEW_TYPES.fancyViewFlat.id) &&
      priceItem.fancyViewFlat > 0 &&
      !hasFancy
    ) {
      flatViewTypes[FLAT_VIEW_TYPES.fancyViewFlat.id] = {
        name: FLAT_VIEW_TYPES.fancyViewFlat.name,
        value: FLAT_VIEW_TYPES.fancyViewFlat,
      };
    }
  });

  return flatViewTypes;
};

export const getAreas = (pricesData, flatViewType) => {
  const areas = [];
  areas.push({
    name: MANUAL_AREA,
    value: 0
  });
  let areasKeys;
  const regex = /\b\w*areas\w*\b/g;
  let counter = 0;
  if (pricesData) {
    areasKeys = Object.keys(pricesData)?.filter((key) => key.match(regex));
  }
  if (areasKeys?.length > 0) {
    areasKeys.map((areasItem) => {
      if (pricesData[areasItem]?.length > 0) {
        pricesData[areasItem]?.map((areaItem, index) => {
          if (areaItem?.length > 0) {
            const trimmedAreaItem = areaItem
              .trim()
              .replace("[", "")
              .replace("]", "");
            const area = trimmedAreaItem.split("|");
            const trimmedAreaFlatType = area[2]?.trim() || 'c';
            const trimmedAreaValue = area[1]?.trim();
            const trimmedAreaName = area[0]?.trim();
            if (!flatViewType?.value?.id) {
              areas.push({
                name: `${trimmedAreaName} ${trimmedAreaValue} м`,
                value: trimmedAreaValue,
                rightSideText: SHORT_FLAT_TYPES[trimmedAreaFlatType].name,
              });
            }

            if (flatViewType?.value?.id && flatViewType?.value?.id === SHORT_FLAT_TYPES[trimmedAreaFlatType].id) {
              areas.push({
                name: `${trimmedAreaName} ${trimmedAreaValue} м`,
                value: trimmedAreaValue,
                rightSideText: SHORT_FLAT_TYPES[trimmedAreaFlatType].name,
              });
            }

            counter++;
          }
        });
      }
    });
  }
  return areas;
};

export const getInstallmentPeriods = (floorRange) => {
  let installmentPeriods = {};
  floorRange?.value?.prices.map((priceItem) => {
    if (priceItem.installmentPeriod) {
      installmentPeriods[priceItem.installmentPeriod] = {
        name: `${priceItem.installmentPeriod} месяцев`,
        value: priceItem.installmentPeriod,
        periodPercent: priceItem.periodPercent,
        rightSideText: priceItem.periodPercent ? `${priceItem.periodPercent}%` : ''
      };
    }
  });
  installmentPeriods.fullPrice = {
    name: 'без рассрочки',
    value: '0'
  }
  return installmentPeriods;
};
